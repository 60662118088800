/* eslint-disable class-methods-use-this */
import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux';
import {VariableSizeList as List} from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {scheduledFetchData} from '../../store/actions/tweets-scheduled';
import PageLoading from '../../components/page-loading/PageLoading';
import Tweet from './TweetToPublish';

const TweetsFeedList = (props) => {
    const {data} = props;
    const {loading} = props;
    const [dataList, setDataList] = useState([]);

    useEffect(() => {
        props.fetchData();
    }, []);

    useEffect(() => {
        setDataList(data);
    }, [data]);

    if (loading) return <PageLoading />;
    return (
        <>
            <div
                style={{
                    height: '700px',
                    width: '100%'
                }}
            >
                <VirtualTweetContainer messages={dataList} />
            </div>
        </>
    );
};

// trick to make autosizer clear cache
const useResetCache = (data) => {
    const ref = React.useRef();
    React.useEffect(() => {
        // AutoSizer causes ref not being present on the component mount
        if (ref.current) {
            ref.current.resetAfterIndex(0);
        }
    }, [data]);
    return ref;
};

const VirtualTweetContainer = ({messages}) => {
    // References
    const rowHeights = useRef({});
    const [messagesList, setMessagesList] = useState([]);
    const listRef = useResetCache(messages);

    function setRowHeight(index, size) {
        listRef.current.resetAfterIndex(0);
        rowHeights.current = {...rowHeights.current, [index]: size};
    }

    function scrollToBottom() {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
            listRef.current.scrollToItem(0, 'start');
        }
    }

    useEffect(() => {
        setMessagesList(messages);
        if (messages.length > 0) {
            scrollToBottom();
        }
        // eslint-disable-next-line
    }, [messages]);

    function getRowHeight(index) {
        return rowHeights.current[index] + 8 || 82;
    }

    // function deleteRowHeight(index) {
    //     rowHeights.current.splice(index, 1);
    // }

    function Row({index, style}) {
        const rowRef = useRef({});

        useEffect(() => {
            if (rowRef.current) {
                setRowHeight(
                    index,
                    rowRef.current.getBoundingClientRect().height
                );
            }
            // eslint-disable-next-line
        }, [rowRef]);

        function onImgLoad() {
            if (rowRef.current) {
                setRowHeight(
                    index,
                    rowRef.current.getBoundingClientRect().height
                );
                listRef.current.resetAfterIndex(0);
            }
        }

        function onDelete() {
            const list = messagesList;
            setMessagesList([
                ...list.slice(0, index),
                ...list.slice(index + 1, list.length)
            ]);
        }

        return (
            <div style={style}>
                <Tweet
                    rowRef={rowRef}
                    key={messagesList[index].tScheduleId}
                    data={messagesList[index]}
                    onImgLoad={onImgLoad}
                    onDelete={onDelete}
                />
            </div>
        );
    }

    return (
        <AutoSizer>
            {({height, width}) => (
                <List
                    className="List"
                    height={height}
                    itemCount={messagesList.length}
                    itemSize={getRowHeight}
                    ref={listRef}
                    width={width}
                    initialScrollOffset={messagesList.length - 1}
                >
                    {Row}
                </List>
            )}
        </AutoSizer>
    );
};

const mapStateToProps = (state) => ({
    data: state.tweetScheduled.filteredData,
    loading: state.tweetScheduled.loading,
    error: state.tweetScheduled.error
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: () => dispatch(scheduledFetchData())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TweetsFeedList);
