import {React, useEffect, useState} from 'react';
import RssStats from '@app/pages/dashboard/RssStats';
import axios from '../../utils/axios';
import SmallBox from '../../components/small-box/SmallBox';
import useIsMounted from '../../utils/custom-hook';

const Dashboard = () => {
    const isMounted = useIsMounted();

    const [quoteCount, setQuoteCount] = useState(0);
    const [imageCount, setImageCount] = useState(0);
    const [videoCount, setVideoCount] = useState(0);
    const [bookCount, setBookCount] = useState(0);
    const [otherCount, setOtherCount] = useState(0);
    const [tweetCount, setTweetCount] = useState(0);

    const getAllBackLogTweets = () => {
        axios
            .get('/backlog-tweet/get-bl-count')
            .then((res) => {
                if (res.data && isMounted.current) {
                    for (let i = 0; i < res.data.length; i += 1) {
                        if (res.data[i]._id.type === 'Quote')
                            setQuoteCount(res.data[i].count);
                        if (res.data[i]._id.type === 'Image')
                            setImageCount(res.data[i].count);
                        if (res.data[i]._id.type === 'Book')
                            setBookCount(res.data[i].count);
                        if (res.data[i]._id.type === 'Other')
                            setOtherCount(res.data[i].count);
                        if (res.data[i]._id.type === 'Video')
                            setVideoCount(res.data[i].count);
                        if (res.data[i]._id.type === 'Tweet')
                            setTweetCount(res.data[i].count);
                    }
                }
                // console.log('Results:', res.data);
            })
            .catch((error) => console.log(`Error: ${error}`));
    };

    useEffect(() => {
        getAllBackLogTweets();

        return () => {};
    }, []);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-6">
                    <h2>Dashboard</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3">
                    <SmallBox
                        count="Tweets Backlog"
                        title="Summary"
                        type="default"
                        icon=""
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={quoteCount}
                        title="Quote"
                        type="success"
                        navigateTo="/"
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={bookCount}
                        title="Book"
                        type="warning"
                        navigateTo="/"
                    />
                </div>

                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={imageCount}
                        title="Image"
                        type="danger"
                        navigateTo="/"
                    />
                </div>
                <div className="col-lg-3">
                    <SmallBox
                        count={
                            quoteCount +
                            imageCount +
                            bookCount +
                            otherCount +
                            videoCount +
                            tweetCount
                        }
                        title="Total"
                        type="default"
                        icon="ion-android-people"
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={tweetCount}
                        title="Tweet"
                        type="primary"
                        navigateTo="/"
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={videoCount}
                        title="Video"
                        type="info"
                        navigateTo="/"
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={otherCount}
                        title="Other"
                        type="secondary"
                        navigateTo="/"
                    />
                </div>
            </div>

            <div>
                <div className="row">
                    <div className="col-md-6">
                        <RssStats historical="" />
                    </div>
                    <div className="col-md-6">
                        <RssStats historical="historical-" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
