import {React, useEffect, useState} from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import {connect} from 'react-redux';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import axios from '../../utils/axios';

const RssStats = ({historical}) => {
    const [rssStats, setRssStats] = useState([]);

    const getRssFeedStats = () => {
        axios
            .get(`/tweet-scheduled/get-${historical}stats`)
            .then((res) => {
                axios.get('/rss-feed/get-latest').then((resList) => {
                    const rssListSorted = [];
                    for (let i = 0; i < resList.data.length; i += 1) {
                        rssListSorted[resList.data[i].rssFeedId] =
                            resList.data[i].name;
                    }

                    for (let j = 0; j < res.data.length; j += 1) {
                        if (rssListSorted[res.data[j]._id.type])
                            res.data[j]._id.type =
                                rssListSorted[res.data[j]._id.type];
                    }
                    setRssStats(res.data);
                });
            })
            .catch((error) => console.log(`Error: ${error}`));
    };

    const viewFormatter = (cell, row) => {
        return (
            <>
                <span className="badge bg-success">{row.count}</span>
            </>
        );
    };

    const nameFormatter = (cell, row) => {
        return (
            <>
                <div className="header-block">
                    <span className="line1">{row._id.type}</span>
                    <span className="line2" />
                </div>
            </>
        );
    };

    const columns = [
        {
            dataField: '_id.type',
            text: 'Full Name',
            sort: true,
            formatter: nameFormatter
            // filter: textFilter()
        },
        {
            dataField: 'count',
            text: 'Count',
            sort: true,
            formatter: viewFormatter,
            align: 'center',
            headerAlign: 'center',
            headerStyle: () => {
                return {width: '20%'};
            }
        }
    ];

    useEffect(() => {
        getRssFeedStats();

        return () => {};
    }, []);

    const options = {
        custom: true,
        totalSize: rssStats.length,
        // page: 1,
        sizePerPageList: [
            {
                text: '5',
                value: 5
            },
            {
                text: '10',
                value: 10
            },
            {
                text: 'All',
                value: rssStats.length
            }
        ],
        // showTotal: true,
        className: 'pagination-sm float-right',
        sizePerPage: 5,
        pageStartIndex: 0,
        paginationSize: 3,
        prePage: 'Prev',
        nextPage: 'Next',
        firstPage: 'First',
        lastPage: 'Last',
        alwaysShowAllBtns: true
    };

    const defaultSorted = [
        {
            dataField: 'name',
            order: 'desc'
        }
    ];

    return (
        <PaginationProvider pagination={paginationFactory(options)}>
            {({paginationProps, paginationTableProps}) => (
                <div>
                    <div>
                        <div className="card card-widget">
                            <div className="card-header">
                                <h3 className="card-title">
                                    {historical
                                        ? `RSS Historical Stats List [${rssStats.length}]`
                                        : `RSS Stats List [${rssStats.length}]`}
                                </h3>
                                <div className="card-tools">
                                    <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                    />
                                    <PaginationListStandalone
                                        className="pagination-sm float-right"
                                        {...paginationProps}
                                    />
                                </div>
                            </div>
                            {/* /.card-header */}
                            <div className="card-body p-0">
                                <BootstrapTable
                                    bootstrap4
                                    bordered={false}
                                    hover
                                    striped
                                    // condensed={true}
                                    keyField="rssFeedId"
                                    data={rssStats}
                                    defaultSorted={defaultSorted}
                                    columns={columns}
                                    // pagination={paginationFactory(options)}
                                    // filter={filterFactory()}
                                    // filterPosition="bottom"
                                    noDataIndication="Table is Empty"
                                    // tabIndexCell
                                    // editRow={true}
                                    {...paginationTableProps}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </PaginationProvider>
    );
};

export default connect(null, null)(RssStats);
