import React, {Component, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {VariableSizeList as List} from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {backlogFetchData} from '../../store/actions/tweets-backlog';
import PageLoading from '../../components/page-loading/PageLoading';
import Tweet from './Tweet';

class TweetsFeedList extends Component {
    componentDidMount() {
        // eslint-disable-next-line
        this.props.fetchData();
    }

    componentDidUpdate() {}

    render() {
        const {data} = this.props;
        const {loading} = this.props;
        if (loading) return <PageLoading />;
        return (
            <div
                style={{
                    height: '700px',
                    width: '100%'
                }}
            >
                <VirtualTweetContainer messages={data} />
            </div>
        );
    }
}

// trick to make autosizer clear cache
const useResetCache = (data) => {
    const ref = React.useRef();
    React.useEffect(() => {
        // AutoSizer causes ref not being present on the component mount
        if (ref.current) {
            ref.current.resetAfterIndex(0);
        }
    }, [data]);
    return ref;
};

const VirtualTweetContainer = ({messages}) => {
    // References
    const rowHeights = useRef({});
    const listRef = useResetCache(messages);

    function setRowHeight(index, size) {
        listRef.current.resetAfterIndex(0);
        rowHeights.current = {...rowHeights.current, [index]: size};
    }

    function scrollToBottom() {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
            listRef.current.scrollToItem(0, 'start');
        }
    }

    useEffect(() => {
        if (messages.length > 0) {
            scrollToBottom();
        }
        // eslint-disable-next-line
    }, [messages]);

    function getRowHeight(index) {
        return rowHeights.current[index] + 8 || 82;
    }

    function Row({index, style}) {
        const rowRef = useRef({});

        useEffect(() => {
            if (rowRef.current) {
                setRowHeight(
                    index,
                    rowRef.current.getBoundingClientRect().height
                );
            }
            // eslint-disable-next-line
        }, [rowRef]);

        function onImgLoad() {
            if (rowRef.current) {
                setRowHeight(
                    index,
                    rowRef.current.getBoundingClientRect().height
                );
                listRef.current.resetAfterIndex(0);
            }
        }

        return (
            <div style={style}>
                <Tweet
                    rowRef={rowRef}
                    key={messages[index]._id}
                    data={messages[index]}
                    onImgLoad={onImgLoad}
                />
            </div>
        );
    }

    return (
        <AutoSizer>
            {({height, width}) => (
                <List
                    className="List"
                    height={height}
                    itemCount={messages.length}
                    itemSize={getRowHeight}
                    ref={listRef}
                    width={width}
                    initialScrollOffset={messages.length - 1}
                >
                    {Row}
                </List>
            )}
        </AutoSizer>
    );
};

const mapStateToProps = (state) => ({
    data: state.tweetBacklog.filteredData,
    loading: state.tweetBacklog.loading,
    error: state.tweetBacklog.error
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: () => dispatch(backlogFetchData())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TweetsFeedList);
