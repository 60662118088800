import React from 'react';

const reactStringReplace = require('react-string-replace');

const TweetText = ({children}) => {
    const REGEX_URL = /(https?:\/\/\S+)/g; // /(?:\s)(f|ht)tps?:\/\/([^\s\t\r\n<]*[^\s\t\r\n<)*_,\.])/g, //regex for urls
    // const REGEX_USER = /\B@([a-zA-Z0-9_]+)/g; // regex for @users
    // // REGEX_HASHTAG = /\B(#[Ã¡-ÃºÃ-ÃÃ¤-Ã¼Ã-Ãa-zA-Z0-9_]+)/g,
    // const REGEX_SYMBOL = /\B\$([Ã¡-ÃºÃ-ÃÃ¤-Ã¼Ã-Ãa-zA-Z]+)/g; // regex for #hashtags

    if (!children) return '';

    let tweet = children.replace(/&amp;/g, '&');

    // // Match @-mentions
    // tweet = reactStringReplace(tweet, REGEX_USER, (match, i) => (
    //     <a
    //         key={match + i}
    //         href={`https://twitter.com/${match}`}
    //         target="mention"
    //     >
    //         @{match}
    //     </a>
    // ));

    // Match URLs
    tweet = reactStringReplace(tweet, REGEX_URL, (match, i) => (
        <a key={match + i} href={match} target="url">
            {match}
        </a>
    ));

    // // Match $ticker
    // tweet = reactStringReplace(tweet, REGEX_SYMBOL, (match, i) => (
    //     <Link key={match + i} to={`/tweets-feed/${match}`}>
    //         {/* // <Link key={match + i} to={`/tweets-users-list`}> */}${match}
    //     </Link>
    // ));

    // convert \n to BR tag
    tweet = reactStringReplace(tweet, /(\n)/g, (item, idx) => {
        return (
            <span key={idx}>
                {item}
                <br />
            </span>
        );
    });

    //     // Match hashtags
    // replacedText = reactStringReplace(replacedText, /#(\w+)/g, (match, i) => (
    //   <a key={match + i} href={`https://twitter.com/hashtag/${match}`}>#{match}</a>
    // ));

    return <div className="mb-3">{tweet}</div>;
};

export default TweetText;
