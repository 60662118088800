import * as ActionTypes from '../actions/actions';

const initialState = {
    isLoggedIn: false,
    token: null,
    currentUser: {
        email: 'mail@example.com',
        picture: null
    }
};

// load persistant user data if already logged in
let persistantAuthState = initialState;
const stateStr = localStorage.getItem('USER_STATE');
if (stateStr) {
    persistantAuthState = JSON.parse(localStorage.getItem('USER_STATE'));
}

const reducer = (state = persistantAuthState, action) => {
    if (action.type === ActionTypes.LOGIN_USER) {
        const {user} = action;
        const newState = {
            isLoggedIn: true,
            token: user.accessToken,
            currentUser: {
                email: user.email,
                picture: user.picture,
                createdAt: user.createdAt
            }
        };
        localStorage.setItem('USER_STATE', JSON.stringify(newState));
        return newState;
    }

    if (action.type === ActionTypes.LOGOUT_USER) {
        localStorage.removeItem('USER_STATE');
        return initialState;
    }
    // if (action.type === ActionTypes.LOAD_USER) {
    //     const {user} = action;
    //     return {
    //         ...state,
    //         user
    //     };
    // }

    return {...state};
};

export default reducer;
