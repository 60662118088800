import React, {useState} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import {connect} from 'react-redux';
import {DateTime} from 'luxon';
import axios from 'utils/axios';
import {toast} from 'react-toastify';
import TweetText from '../../components/tweet-text/TweetText';
import Button from '../../components/button/Button';
import Modal from '../../components/modal/Modal';

const Tweet = ({data, rowRef, onImgLoad, onDelete}) => {
    const {url} = useRouteMatch();
    const [show, setShow] = useState(false);
    const [deleted, setDeleted] = useState(false);

    if (!data || data.length <= 0) return '';

    // console.log('Data after : ', data);

    const handleClose = () => setShow(false);
    const handleConfirm = () => {
        setShow(false);
        axios({
            method: 'post',
            url: '/tweet-scheduled/delete',
            data: {tScheduledReference: data.tScheduleId}
        })
            .then(() => {
                // send notification
                toast.warn('Deleted');
                setDeleted(true);
                // onImgLoad();
                // console.log('AXIOS suuccceess');
                // fetchData();
                onDelete();
            })
            .catch((error) => {
                console.log('AXIOS failed', error);
                toast.warn(error);
            });
    };
    const handleShow = () => setShow(true);

    const warningMessage = `Are you sure you want to delete it?`;

    return (
        <div
            ref={rowRef}
            className={
                deleted
                    ? 'card card-widget text-white bg-danger collapse'
                    : 'card card-widget  '
            }
        >
            <div className="card-header">
                <div className="user-block">
                    <img
                        className="img-circle"
                        src="https://pbs.twimg.com/profile_images/709021104107433984/lrxKmheW_400x400.jpg"
                        // src={user.picture}
                        alt=""
                    />
                    <span className="username">Emmanuel Siegel</span>
                    <span className="description">
                        Created :{' '}
                        <span className="font-weight-bold">
                            {DateTime.fromISO(data.createdAt).toFormat(
                                'dd LLL yyyy'
                            )}
                        </span>
                        - To Published:{' '}
                        <span className="font-weight-bold">
                            {data.toPublish
                                ? DateTime.fromISO(data.toPublish).toFormat(
                                      'dd LLL yyyy - HH:mm:ss'
                                  )
                                : 'Not Yet'}
                        </span>
                    </span>
                </div>

                <div className="card-tools">
                    <span className="right badge badge-pill badge-warning mr-1">
                        {data.status}
                    </span>
                    <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                    >
                        <i className="fas fa-minus" />
                    </button>
                </div>
            </div>

            <div className="card-body">
                <TweetText key={data.tweetTxt}>{data.tweetTxt}</TweetText>
                {data.tweetImagePath
                    ? data.tweetImagePath.map((image) => {
                          return (
                              <img
                                  onLoad={onImgLoad}
                                  key={image}
                                  className="img-fluid pad mb-3"
                                  src={`${process.env.REACT_APP_RELATIVE_URL_ROOT}/public/${image}`}
                                  alt=""
                              />
                          );
                      })
                    : null}

                <span className="float-right text-muted">
                    <Link to={`${url}/edit/${data.tScheduleId}`}>
                        <Button
                            type="button"
                            className="btn btn-primary btn-sm ml-2"
                        >
                            Edit
                        </Button>
                    </Link>

                    <Button
                        type="button"
                        className="btn btn-danger btn-sm ml-2"
                        onClick={handleShow}
                    >
                        Delete
                    </Button>

                    <Modal
                        handleClose={handleClose}
                        handleConfirm={handleConfirm}
                        show={show}
                        title="Confirmation"
                        body={warningMessage}
                    />
                </span>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

export default connect(mapStateToProps, null)(Tweet);
