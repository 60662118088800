import * as ActionTypes from '../actions/actions';

const initialState = {
    data: [],
    filteredData: [],
    loading: false,
    error: ''
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_BACKLOG_LOADING: {
            return {
                ...state,
                loading: true,
                error: ''
            };
        }
        case ActionTypes.LOAD_BACKLOG_SUCCESS: {
            return {
                ...state,
                data: action.data,
                filteredData: action.data,
                loading: false
            };
        }
        case ActionTypes.LOAD_BACKLOG_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case ActionTypes.FILTER_BACKLOG: {
            let {filterSearch} = action;
            filterSearch = filterSearch.toLowerCase();
            const filteredData = state.data.filter((filData) => {
                return (
                    filData.content.toLowerCase().search(filterSearch) !== -1
                );
            });

            return {
                ...state,
                filteredData,
                loading: false
            };
        }

        default: {
            return state;
        }
    }
};

export default reducer;
