export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOAD_USER = 'LOAD_USER';

export const LOAD_BACKLOG_LOADING = 'REDUX_THUNK_LOAD_BACKLOG_LOADING';
export const LOAD_BACKLOG_SUCCESS = 'REDUX_THUNK_LOAD_BACKLOG_SUCCESS';
export const LOAD_BACKLOG_ERROR = 'REDUX_THUNK_LOAD_BACKLOG_ERROR';
export const FILTER_BACKLOG = 'REDUX_THUNK_FILTER_BACKLOG_SUCCESS';

export const LOAD_RSSFEED_LOADING = 'REDUX_THUNK_LOAD_RSSFEED_LOADING';
export const LOAD_RSSFEED_SUCCESS = 'REDUX_THUNK_LOAD_RSSFEED_SUCCESS';
export const LOAD_RSSFEED_ERROR = 'REDUX_THUNK_LOAD_RSSFEED_ERROR';

export const LOAD_RSSURLS_LOADING = 'REDUX_THUNK_LOAD_RSSURLS_LOADING';
export const LOAD_RSSURLS_SUCCESS = 'REDUX_THUNK_LOAD_RSSURLS_SUCCESS';
export const LOAD_RSSURLS_ERROR = 'REDUX_THUNK_LOAD_RSSURLS_ERROR';

export const LOAD_T_SCHEDULE_LOADING = 'REDUX_THUNK_LOAD_T_SCHEDULE_LOADING';
export const LOAD_T_SCHEDULE_SUCCESS = 'REDUX_THUNK_LOAD_T_SCHEDULE_SUCCESS';
export const LOAD_T_SCHEDULE_ERROR = 'REDUX_THUNK_LOAD_T_SCHEDULE_ERROR';
export const FILTER_T_SCHEDULE = 'REDUX_THUNK_FILTER_T_SCHEDULE_SUCCESS';
export const FILTER_TOMORROW_T_SCHEDULE =
    'REDUX_THUNK_FILTER_TOMORROW_T_SCHEDULE_SUCCESS';
