import {React, useEffect, Fragment} from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import {connect} from 'react-redux';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import {Link, useRouteMatch} from 'react-router-dom';
import axios from '../../utils/axios';
import {rssUrlsFetchData} from '../../store/actions/rss-urls';
import {rssFeedFetchData} from '../../store/actions/rss-feed';

const RssUrlList = (props) => {
    const {data} = props;
    const {url} = useRouteMatch();

    const handleViewClick = (feedUrl) => {
        // console.log('url', url);
        props.fetchFeedData(feedUrl);
    };

    const handleDeleteClick = (id) => {
        // console.log('id', id);
        axios({
            method: 'post',
            url: '/rss-feed/delete',
            data: {rssFeedReference: id}
        })
            .then(() => {
                props.fetchUrlsData();
            })
            .catch((error) => {
                console.log('AXIOS failed', error);
            });
    };

    const viewFormatter = (cell, row) => {
        return (
            <>
                <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => handleViewClick(row.url)}
                >
                    <i className="far fa-eye" />
                </button>
                <Link to={`${url}/edit/${row.rssFeedId}`}>
                    <button
                        type="button"
                        className="btn btn-primary btn-sm ml-2"
                    >
                        <i className="fas fa-pencil-alt" />
                    </button>
                </Link>
                <button
                    type="button"
                    className="btn btn-danger btn-sm ml-2"
                    onClick={() => handleDeleteClick(row.rssFeedId)}
                >
                    <i className="far fa-trash-alt" />
                </button>
            </>
        );
    };

    const nameFormatter = (cell, row) => {
        return (
            <>
                <div className="header-block">
                    <span className="line1">
                        {row.name} - <a href={row.url}>[Link]</a>
                    </span>
                    <span className="line2">
                        {row.hashtags
                            ? row.hashtags.map((tag) => {
                                  return `${tag} `;
                              })
                            : ' '}
                    </span>
                </div>
            </>
        );
    };

    const columns = [
        {
            dataField: 'name',
            text: 'Full Name',
            sort: true,
            formatter: nameFormatter,
            filter: textFilter()
        },
        {
            dataField: 'rssFeedId',
            text: 'View',
            formatter: viewFormatter,
            align: 'center',
            headerAlign: 'center',
            headerStyle: () => {
                return {width: '28%'};
            }
        }
    ];

    useEffect(() => {
        props.fetchUrlsData();

        return () => {};
    }, []);

    const options = {
        custom: true,
        totalSize: data.length,
        // page: 1,
        sizePerPageList: [
            {
                text: '5',
                value: 5
            },
            {
                text: '10',
                value: 10
            },
            {
                text: 'All',
                value: data.length
            }
        ],
        // showTotal: true,
        className: 'pagination-sm float-right',
        sizePerPage: 5,
        pageStartIndex: 0,
        paginationSize: 3,
        prePage: 'Prev',
        nextPage: 'Next',
        firstPage: 'First',
        lastPage: 'Last',
        alwaysShowAllBtns: true
    };

    const defaultSorted = [
        {
            dataField: 'name',
            order: 'desc'
        }
    ];

    return (
        <PaginationProvider pagination={paginationFactory(options)}>
            {({paginationProps, paginationTableProps}) => (
                <div>
                    <div>
                        <div className="card card-widget">
                            <div className="card-header">
                                <h3 className="card-title">
                                    RSS Feed List [{data.length}]
                                </h3>
                                <div className="card-tools">
                                    <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                    />
                                    <PaginationListStandalone
                                        className="pagination-sm float-right"
                                        {...paginationProps}
                                    />
                                </div>
                            </div>
                            {/* /.card-header */}
                            <div className="card-body p-0">
                                <BootstrapTable
                                    bootstrap4
                                    bordered={false}
                                    hover
                                    striped
                                    // condensed={true}
                                    keyField="rssFeedId"
                                    data={data}
                                    defaultSorted={defaultSorted}
                                    columns={columns}
                                    // pagination={paginationFactory(options)}
                                    filter={filterFactory()}
                                    filterPosition="bottom"
                                    noDataIndication="Table is Empty"
                                    // tabIndexCell
                                    // editRow={true}
                                    {...paginationTableProps}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </PaginationProvider>
    );
};

const mapStateToProps = (state) => ({
    data: state.rssUrls.data,
    loading: state.rssUrls.loading,
    error: state.rssUrls.error
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUrlsData: () => dispatch(rssUrlsFetchData()),
        fetchFeedData: (url) => dispatch(rssFeedFetchData(url))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RssUrlList);
