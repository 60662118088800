/* eslint-disable no-use-before-define */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import {useParams, useHistory} from 'react-router-dom';
import axios from 'utils/axios';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {Alert} from 'react-bootstrap';
import {connect} from 'react-redux';
import Button from '../../components/button/Button';
import {rssUrlsFetchData} from '../../store/actions/rss-urls';
import InputTag from '../../components/input-tags/InputTags';

const InsertRss = (props) => {
    const {editId} = useParams();
    const isAddMode = !editId;
    const history = useHistory();
    const [tags, setTags] = useState([]);

    const printFormError = (formik, key) => {
        if (formik.touched[key] && formik.errors[key]) {
            return (
                <Alert variant="warning" className="mt-2">
                    {formik.errors[key]}
                </Alert>
            );
        }
        return null;
    };

    const createRecord = (values, actions) => {
        values.hashtags = tags;
        axios({
            method: 'post',
            url: '/rss-feed/add',
            data: values
        })
            .then(() => {
                actions.setSubmitting(false);
                resetForm();
                toast.warn('Submitted');
                props.fetchData();
            })
            .catch((error) => {
                console.log('AXIOS failed', error);
                actions.setSubmitting(false);
                toast.warn(error.message);
            });
    };

    const updateRecord = (values, actions) => {
        values.rssFeedReference = editId;
        values.hashtags = tags;
        axios({
            method: 'post',
            url: '/rss-feed/update',
            data: values
        })
            .then(() => {
                actions.setSubmitting(false);
                resetForm();
                toast.warn('Updated');
                props.fetchData();
                history.push('/rss-feed');
            })
            .catch((error) => {
                console.log('AXIOS failed', error);
                actions.setSubmitting(false);
                toast.warn(error.message);
            });
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            url: '',
            hashtags: []
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            url: Yup.string().url().required('Required')
        }),
        onSubmit: (values, actions) => {
            return isAddMode
                ? createRecord(values, actions)
                : updateRecord(values, actions);
        }
    });

    const resetForm = () => {
        formik.resetForm();
    };

    const handleHashtagsChange = (hashtags) => {
        setTags(hashtags);
    };

    useEffect(() => {
        if (!isAddMode) {
            // get user and set form fields
            axios
                .get(`/rss-feed/get-details/${editId}`)

                .then((result) => {
                    formik.setFieldValue('name', result.data.name);
                    formik.setFieldValue('url', result.data.url);
                    formik.setFieldValue('hashtags', result.data.hashtags);
                })
                .catch((error) => {
                    console.log('AXIOS failed', error);
                });
        } else {
            resetForm();
        }
    }, [editId]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div
                className={
                    isAddMode
                        ? 'card card-widget card-primary collapsed-card'
                        : 'card card-widget text-white bg-danger'
                }
            >
                <div className="card-header">
                    <h3 className="card-title">
                        {isAddMode ? 'Add New RSS' : 'Edit Mode'}
                    </h3>
                    <div className="card-tools">
                        <button
                            type="button"
                            className="btn btn-tool"
                            data-card-widget="collapse"
                        >
                            <i className="fas fa-plus" />
                        </button>
                    </div>{' '}
                </div>

                <div className="card-body">
                    <div className="form-group">
                        <label htmlFor="inputSymbol">Rss Link</label>
                        <textarea
                            type="text"
                            name="url"
                            className="form-control"
                            id="inputSymbol"
                            placeholder="Enter URL"
                            // required
                            {...formik.getFieldProps('url')}
                        />
                        {printFormError(formik, 'url')}
                    </div>
                    <div className="form-group">
                        <label htmlFor="selectType">RSS Name</label>
                        <input
                            name="name"
                            className="form-control"
                            id="selectType"
                            {...formik.getFieldProps('name')}
                        />
                        {printFormError(formik, 'name')}
                    </div>
                    <div className="form-group">
                        <label htmlFor="selectType">Hashtags</label>
                        <InputTag
                            onChange={handleHashtagsChange}
                            data={formik.values.hashtags}
                        />
                    </div>
                </div>

                <div className="card-footer">
                    <Button
                        block
                        type="button"
                        isLoading={formik.isSubmitting}
                        disabled={formik.isSubmitting}
                        onClick={formik.handleSubmit}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </form>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: () => dispatch(rssUrlsFetchData())
    };
};

export default connect(null, mapDispatchToProps)(InsertRss);
