import {DateTime} from 'luxon';
import * as ActionTypes from '../actions/actions';

const initialState = {
    data: [],
    filteredData: [],
    filter: {search: '', tomorrow: 0},
    loading: false,
    error: ''
};

const reducer = (state = initialState, action) => {
    const filterSearchResults = (data, filter) => {
        const filterSearch = filter.toLowerCase();
        const results = data.filter((filData) => {
            return filData.tweetTxt.toLowerCase().search(filterSearch) !== -1;
        });
        return results;
    };

    const filterTomorrowResults = (data, filter) => {
        if (!filter) return data;

        const now = DateTime.now();

        const results = data.filter((filData) => {
            if (
                DateTime.fromISO(filData.toPublish).startOf('day') >
                now.startOf('day')
            )
                return filData;
            return null;
        });
        return results;
    };

    const filterData = (data, filter) => {
        let results = filterSearchResults(data, filter.search);
        results = filterTomorrowResults(results, filter.tomorrow);
        return results;
    };

    switch (action.type) {
        case ActionTypes.LOAD_T_SCHEDULE_LOADING: {
            return {
                ...state,
                loading: true,
                error: ''
            };
        }
        case ActionTypes.LOAD_T_SCHEDULE_SUCCESS: {
            const filteredData = filterData(action.data, state.filter);

            return {
                ...state,
                data: action.data,
                filteredData,
                loading: false
            };
        }
        case ActionTypes.FILTER_T_SCHEDULE: {
            const filterNew = state.filter;
            filterNew.search = action.filterSearch;

            const filteredData = filterData(state.data, filterNew);

            return {
                ...state,
                filteredData,
                filter: filterNew,
                loading: false
            };
        }

        case ActionTypes.FILTER_TOMORROW_T_SCHEDULE: {
            const filterNew = state.filter;
            filterNew.tomorrow = action.filterDate;

            const filteredData = filterData(state.data, filterNew);

            return {
                ...state,
                filteredData,
                filter: filterNew,
                loading: false
            };
        }

        case ActionTypes.LOAD_T_SCHEDULE_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        default: {
            return state;
        }
    }
};

export default reducer;
