import DisplayRssFeed from '@app/pages/rss-feed/DisplayRssFeed';
import InsertRss from '@app/pages/rss-feed/InsertRss';
import RssUrlList from '@app/pages/rss-feed/RssUrlList';
import {React} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Route, Switch, Link, useRouteMatch} from 'react-router-dom';

const RssFeed = (props) => {
    const [t] = useTranslation();
    const {title} = props;
    const {path} = useRouteMatch();

    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>{title}</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="/">{t('header.label.home')}</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    RSS Feed
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <Switch>
                                <Route exact path={path}>
                                    <InsertRss />
                                </Route>
                                <Route path={`${path}/edit/:editId`}>
                                    <InsertRss />
                                </Route>
                            </Switch>

                            <RssUrlList />
                        </div>
                        <div className="col-md-6">
                            <DisplayRssFeed />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

const mapStateToProps = (state) => ({
    title: state.rssFeed.title,
    loading: state.rssFeed.loading,
    error: state.rssFeed.error
});

export default connect(mapStateToProps, null)(RssFeed);
