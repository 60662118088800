import React, {useState, useEffect} from 'react';
import './InputTags.css';

const InputTag = ({onChange, data = []}) => {
    const [tags, setTags] = useState(data);

    const removeTag = (i) => {
        const newTag = [...tags];
        newTag.splice(i, 1);
        setTags(newTag);
    };

    useEffect(() => {
        onChange(tags);
    }, [tags]);

    useEffect(() => {
        setTags(data);
    }, [data]);

    const inputKeyDown = (e) => {
        const val = e.target.value;
        if (e.key === 'Enter' && val) {
            if (tags.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
                return;
            }

            setTags([...tags, val]);
            e.target.value = null;
        } else if (e.key === 'Backspace' && !val) {
            removeTag(tags.length - 1);
        }
    };

    return (
        <div className="input-tag">
            <ul className="input-tag__tags">
                {tags.map((tag, i) => (
                    <li key={tag}>
                        {tag}
                        <button
                            type="button"
                            onClick={() => {
                                removeTag(i);
                            }}
                        >
                            +
                        </button>
                    </li>
                ))}
                <li className="input-tag__tags__input">
                    <input type="text" onKeyDown={inputKeyDown} />
                </li>
            </ul>
        </div>
    );
};

export default InputTag;
