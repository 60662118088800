import {React} from 'react';
import {useTranslation} from 'react-i18next';
import TweetsFeedList from '@app/pages/backlog/TweetsFeedList';
import {Route, Switch, Link, useRouteMatch} from 'react-router-dom';
import BacklogSearchList from '@app/pages/backlog/BacklogSearchList';
import InsertTweet from './InsertTweet';

const BackLog = () => {
    const [t] = useTranslation();
    const {path} = useRouteMatch();

    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Tweets BackLog</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="/">{t('header.label.home')}</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    BackLog
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <TweetsFeedList />
                        </div>
                        <div className="col-md-6">
                            <BacklogSearchList />
                            <Switch>
                                <Route exact path={path}>
                                    <InsertTweet />
                                </Route>
                                <Route path={`${path}/edit/:editId`}>
                                    <InsertTweet />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BackLog;
