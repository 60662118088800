import * as ActionTypes from '../actions/actions';

const initialState = {
    data: [],
    loading: false,
    error: ''
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_RSSURLS_LOADING: {
            return {
                ...state,
                loading: true,
                error: ''
            };
        }
        case ActionTypes.LOAD_RSSURLS_SUCCESS: {
            return {
                ...state,
                data: action.data,
                loading: false
            };
        }
        case ActionTypes.LOAD_RSSURLS_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        default: {
            return state;
        }
    }
};

export default reducer;
