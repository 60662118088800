/* eslint-disable class-methods-use-this */
import React from 'react';
import {connect} from 'react-redux';
import {filterTomorrowDate} from '../../store/actions/tweets-scheduled';
import Button from '../../components/button/Button';

const TweetDateFilter = (props) => {
    const handleTomorrowDate = (filter) => {
        props.filterTomorrowDate(filter);
    };

    return (
        <>
            <div className="card card-widget card-primary">
                <div className="card-header">
                    <h3 className="card-title">Date Filter</h3>

                    <div className="card-tools">
                        <button
                            type="button"
                            className="btn btn-tool"
                            data-card-widget="collapse"
                        >
                            <i className="fas fa-minus" />
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <Button
                                type="button"
                                className="btn btn-success btn-sm ml-2"
                                onClick={() => handleTomorrowDate(0)}
                            >
                                All
                            </Button>
                            <Button
                                type="button"
                                className="btn btn-success btn-sm ml-2"
                                onClick={() => handleTomorrowDate(1)}
                            >
                                Tomorrow
                            </Button>
                        </div>
                        <div className="col-md-6" />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        filterTomorrowDate: (filter) => dispatch(filterTomorrowDate(filter))
    };
};

export default connect(null, mapDispatchToProps)(TweetDateFilter);
