/* eslint-disable no-nested-ternary */
import React from 'react';
import {DateTime} from 'luxon';
import parse from 'html-react-parser';
import OnImagesLoaded from 'react-on-images-loaded';

const Item = ({data, rowRef, onImgLoad}) => {
    const item = data;
    return (
        <div ref={rowRef} key={item.title} className="card card-widget">
            <OnImagesLoaded
                onLoaded={onImgLoad}
                onTimeout={onImgLoad}
                timeout={7000}
            >
                <div className="card-header">
                    <div className="header-block">
                        <span className="line1">{item.title}</span>
                        <span className="line2">
                            Created :{' '}
                            <span className="font-weight-bold">
                                {DateTime.fromISO(item.isoDate).toFormat(
                                    'dd LLL yyyy'
                                )}
                            </span>
                        </span>
                    </div>
                </div>

                <div className="card-body">
                    {item.contentEncoded
                        ? parse(item.contentEncoded)
                        : item.content
                        ? parse(item.content)
                        : ' '}

                    <span className="float-right text-muted mt-3">
                        <a
                            className="btn-floating btn btn-tw"
                            target="_blank"
                            type="button"
                            role="button"
                            title="Share on twitter"
                            href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                                item.guid
                            )}&text=${encodeURIComponent(
                                item.title
                            )}&hashtags=${encodeURIComponent('in')}`}
                            rel="noopener noreferrer"
                        >
                            <button
                                type="button"
                                className="btn btn-default btn-sm ml-2"
                            >
                                <i className="fab fa-twitter" /> Share Post
                            </button>
                        </a>
                        <a
                            className="btn-floating btn btn-tw"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={item.link}
                        >
                            <button
                                type="button"
                                className="btn btn-success btn-sm ml-2"
                            >
                                <i className="fas fa-link" /> View Link Post
                            </button>
                        </a>
                    </span>
                </div>
            </OnImagesLoaded>
        </div>
    );
};

export default Item;
