// import axios from 'utils/axios';
import RSSParser from 'rss-parser';
import * as ActionTypes from './actions';

export function rssFeedHasErrored(error) {
    return {
        type: ActionTypes.LOAD_RSSFEED_ERROR,
        error
    };
}
export function rssFeedIsLoading(bool) {
    return {
        type: ActionTypes.LOAD_RSSFEED_LOADING,
        loading: bool
    };
}
export function rssFeedFetchDataSuccess(data) {
    return {
        type: ActionTypes.LOAD_RSSFEED_SUCCESS,
        data
    };
}

export function rssFeedFetchData(url) {
    return (dispatch) => {
        dispatch(rssFeedIsLoading(true));

        const CORS_PROXY = `${process.env.REACT_APP_APIGATEWAY_URL}rss-feed/proxy?url=`;
        const parser = new RSSParser({
            customFields: {
                item: [['content:encoded', 'contentEncoded']]
            }
        });
        parser.parseURL(`${CORS_PROXY}${url}`, (err, feedResult) => {
            if (err) {
                dispatch(rssFeedHasErrored(err));
                throw err;
            }
            dispatch(rssFeedIsLoading(false));
            dispatch(rssFeedFetchDataSuccess(feedResult));
        });
    };
}
