import axios from 'utils/axios';
import * as ActionTypes from './actions';

export function backlogHasErrored(error) {
    return {
        type: ActionTypes.LOAD_T_SCHEDULE_ERROR,
        error
    };
}
export function backlogIsLoading(bool) {
    return {
        type: ActionTypes.LOAD_T_SCHEDULE_LOADING,
        loading: bool
    };
}
export function backlogFetchDataSuccess(data) {
    return {
        type: ActionTypes.LOAD_T_SCHEDULE_SUCCESS,
        data
    };
}

export function filterData(filterSearch) {
    return {
        type: ActionTypes.FILTER_T_SCHEDULE,
        filterSearch
    };
}

export function filterTomorrowDate(filterDate) {
    return {
        type: ActionTypes.FILTER_TOMORROW_T_SCHEDULE,
        filterDate
    };
}

export function scheduledFetchData() {
    return (dispatch) => {
        dispatch(backlogIsLoading(true));
        axios
            .get('/tweet-scheduled/get-latest')
            .then((response) => {
                dispatch(backlogIsLoading(false));
                return response;
            })
            .then((response) => response.data)
            .then((data) => dispatch(backlogFetchDataSuccess(data)))
            .catch((error) => dispatch(backlogHasErrored(error)));
    };
}
