/* eslint-disable no-use-before-define */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import React, {useState, useEffect} from 'react';
import {useFormik} from 'formik';
import {DateTime} from 'luxon';
import {useParams, useHistory} from 'react-router-dom';
import axios from 'utils/axios';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {Alert} from 'react-bootstrap';
import {connect} from 'react-redux';
import Button from '../../components/button/Button';
import {scheduledFetchData} from '../../store/actions/tweets-scheduled';

const InputTweetToPublish = (props) => {
    const {editId} = useParams();
    const isAddMode = !editId;
    const history = useHistory();

    const [letterCount, setLetterCount] = useState(0);

    const printFormError = (formik, key) => {
        if (formik.touched[key] && formik.errors[key]) {
            return (
                <Alert variant="warning" className="mt-2">
                    {formik.errors[key]}
                </Alert>
            );
        }
        return null;
    };

    const handleTweetChange = (event) => {
        setLetterCount(event.target.value.length);
    };

    const createRecord = (values, actions) => {
        axios({
            method: 'post',
            url: '/backlog-tweet/add',
            data: values,
            headers: {'Content-Type': 'multipart/form-data'}
        })
            .then(() => {
                actions.setSubmitting(false);
                resetForm();
                toast.warn('Submitted');
                props.fetchData();
            })
            .catch((error) => {
                console.log('AXIOS failed', error);
                actions.setSubmitting(false);
                toast.warn(error.message);
            });
    };

    const updateRecord = (values, actions) => {
        values.tScheduleReference = editId;
        // console.log(values);
        axios({
            method: 'post',
            url: '/tweet-scheduled/update',
            data: values
        })
            .then(() => {
                actions.setSubmitting(false);
                resetForm();
                toast.warn('Updated');
                props.fetchData();
                history.push('/scheduled');
            })
            .catch((error) => {
                console.log('AXIOS failed', error);
                actions.setSubmitting(false);
                toast.warn(error.message);
            });
    };

    const formik = useFormik({
        initialValues: {
            content: '',
            toPublish: ''
        },
        validationSchema: Yup.object({
            content: Yup.string(),
            toPublish: Yup.string()
        }),
        onSubmit: (values, actions) => {
            return isAddMode
                ? createRecord(values, actions)
                : updateRecord(values, actions);
        }
    });

    const resetForm = () => {
        formik.resetForm();
        setLetterCount(0);
    };

    useEffect(() => {
        if (!isAddMode) {
            // get user and set form fields
            axios
                .get(`/tweet-scheduled/get-details/${editId}`)

                .then((result) => {
                    formik.setFieldValue('content', result.data.tweetTxt);
                    formik.setFieldValue(
                        'toPublish',
                        DateTime.fromISO(result.data.toPublish)
                            .setZone('Hongkong')
                            .toISO()
                    );
                })
                .catch((error) => {
                    console.log('AXIOS failed', error);
                });
        } else {
            resetForm();
        }
    }, [editId]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div
                className={
                    isAddMode
                        ? 'card card-widget card-primary'
                        : 'card card-widget text-white bg-danger'
                }
            >
                <div className="card-header">
                    <h3 className="card-title">
                        {isAddMode ? 'Add New Tweet' : 'Edit Mode'}
                    </h3>

                    <div className="card-tools">
                        <button
                            type="button"
                            className="btn btn-tool"
                            data-card-widget="collapse"
                        >
                            <i className="fas fa-minus" />
                        </button>
                    </div>
                </div>

                <div className="card-body">
                    <div className="form-group">
                        <label htmlFor="inputSymbol">Tweet Content</label>
                        <textarea
                            type="text"
                            name="content"
                            className="form-control"
                            id="inputSymbol"
                            placeholder="Enter symbol2"
                            onKeyUp={handleTweetChange}
                            // required
                            {...formik.getFieldProps('content')}
                        />
                        {printFormError(formik, 'content')}
                        <span>
                            Count: <b>{letterCount}</b>
                        </span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputDate">To Publish</label>
                        <input
                            type="text"
                            name="toPublish"
                            className="form-control"
                            id="inputDate"
                            {...formik.getFieldProps('toPublish')}
                        />
                        {printFormError(formik, 'toPublish')}
                    </div>
                </div>

                <div className="card-footer">
                    <Button
                        block
                        type="submit"
                        isLoading={formik.isSubmitting}
                        disabled={formik.isSubmitting}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </form>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: () => dispatch(scheduledFetchData())
    };
};

export default connect(null, mapDispatchToProps)(InputTweetToPublish);
