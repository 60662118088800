import * as ActionTypes from '../actions/actions';

const initialState = {
    title: 'Rss Feed',
    data: [],
    loading: false,
    error: ''
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_RSSFEED_LOADING: {
            return {
                ...state,
                loading: true,
                error: ''
            };
        }
        case ActionTypes.LOAD_RSSFEED_SUCCESS: {
            return {
                ...state,
                data: action.data.items,
                title: action.data.title,
                loading: false
            };
        }
        case ActionTypes.LOAD_RSSFEED_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        default: {
            return state;
        }
    }
};

export default reducer;
