import axios from 'utils/axios';

export const loginByAuth = async (email, password) => {
    const res = await axios.post('/user/login', {email, password});
    const {user} = res.data;
    // document.getElementById('root').classList.remove('login-page');
    // document.getElementById('root').classList.remove('hold-transition');
    return user;
};

export const registerByAuth = async (email, password) => {
    const res = await axios.post('/user/signup', {email, password});
    const {user} = res.data;
    // document.getElementById('root').classList.remove('register-page');
    // document.getElementById('root').classList.remove('hold-transition');
    return user;
};

export const logoutByAuth = async () => {
    const res = await axios.get('/user/logout');
    return res;
};
