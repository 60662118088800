import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunkMiddleware from 'redux-thunk';
import authReducer from './reducers/auth';
import tweetBackLogReducer from './reducers/tweets-backlog';
import tweetScheduledReducer from './reducers/tweets-scheduled';
import rssUrlReducer from './reducers/rss-urls';
import rssFeedReducer from './reducers/rss-feed';

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    auth: authReducer,
    tweetBacklog: tweetBackLogReducer,
    tweetScheduled: tweetScheduledReducer,
    rssUrls: rssUrlReducer,
    rssFeed: rssFeedReducer
});

const middleware = [
    /* YOUR CUSTOM MIDDLEWARES HERE */
    thunkMiddleware
];

const store = createStore(
    rootReducer /* preloadedState, */,
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    storeEnhancers(applyMiddleware(...middleware))
);

// store.subscribe(() => {
//     console.log('current state', store.getState());
// });

export default store;
