import InputTweetToPublish from '@app/pages/scheduled/InputTweetToPublish';
import TweetsSearchList from '@app/pages/scheduled/TweetsSearchList';
import TweetsDateFilter from '@app/pages/scheduled/TweetsDateFilter';
import {React} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Route, Switch, Link, useRouteMatch} from 'react-router-dom';
import TweetsFeedList from './TweetsFeedList';

const Scheduled = (props) => {
    const [t] = useTranslation();
    const {path} = useRouteMatch();
    const {data} = props;

    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Tweets Scheduled [{data.length}]</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="/">{t('header.label.home')}</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    Scheduled
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <TweetsFeedList />
                        </div>
                        <div className="col-md-6">
                            <Switch>
                                <Route exact path={path}>
                                    <TweetsSearchList />
                                    <TweetsDateFilter />
                                </Route>
                                <Route path={`${path}/edit/:editId`}>
                                    <InputTweetToPublish />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

const mapStateToProps = (state) => ({
    data: state.tweetScheduled.filteredData
});

export default connect(mapStateToProps, null)(Scheduled);
