import React from 'react';
import {connect} from 'react-redux';
import {NavLink, Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const MenuSidebar = ({user}) => {
    const [t] = useTranslation();

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <Link to="/" className="brand-link">
                <img
                    src="/img/logo.png"
                    alt="AdminLTE Logo"
                    className="brand-image img-circle elevation-3"
                    style={{opacity: '.8'}}
                />
                <span className="brand-text font-weight-light">
                    Social Xpozur
                </span>
            </Link>
            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img
                            src={user.picture || '/img/default-profile.png'}
                            className="img-circle elevation-2"
                            alt="User"
                        />
                    </div>
                    <div className="info">
                        <Link to="/profile" className="d-block">
                            {user.email}
                        </Link>
                    </div>
                </div>
                <nav className="mt-2" style={{overflowY: 'hidden'}}>
                    <ul
                        className="nav nav-pills nav-sidebar flex-column"
                        data-widget="treeview"
                        role="menu"
                        data-accordion="false"
                    >
                        <li className="nav-item">
                            <NavLink to="/" exact className="nav-link">
                                <i className="nav-icon fas fa-tachometer-alt" />
                                <p>{t('menusidebar.label.dashboard')}</p>
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to="/backlog" exact className="nav-link">
                                <i className="nav-icon fab fa-twitter-square" />
                                <p>Back Log</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/rss-feed" exact className="nav-link">
                                <i className="nav-icon fas fa-rss" />
                                <p>RSS Feed</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/scheduled" exact className="nav-link">
                                <i className="nav-icon fa fa-clock" />
                                <p>Tweet Scheduled</p>
                            </NavLink>
                        </li>
                        <li className="nav-item has-treeview menu-closed">
                            <a href="#menu" className="nav-link">
                                <i className="nav-icon fab fa-twitter-square" />
                                <p>
                                    Archive
                                    <i className="right fas fa-angle-left" />
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <NavLink
                                        to="/profile"
                                        exact
                                        className="nav-link"
                                    >
                                        <i className="nav-icon fas fa-user" />
                                        <p>{t('menusidebar.label.profile')}</p>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

export default connect(mapStateToProps, null)(MenuSidebar);
