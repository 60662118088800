import React from 'react';

export const TweetType = ['Quote', 'Image', 'Video', 'Tweet', 'Book', 'Other'];

const TweetCategoryBox = ({category}) => {
    let type;
    switch (category) {
        case 'Quote':
            type = 'primary';
            break;
        case 'Image':
            type = 'secondary';
            break;
        case 'Video':
            type = 'success';
            break;
        case 'Tweet':
            type = 'danger';
            break;
        // case 'Quote':
        //     type = 'warning';
        //     break;
        case 'Book':
            type = 'info';
            break;
        // case 'Quote':
        //     type = 'light';
        //     break;
        default:
            type = 'dark';
            break;
    }
    return (
        <span className={`right badge badge-pill badge-${type} mr-1`}>
            {category}
        </span>
    );
};

export default TweetCategoryBox;
