/* eslint-disable class-methods-use-this */
import React from 'react';
import {connect} from 'react-redux';
import {filterData} from '../../store/actions/tweets-backlog';

const BacklogSearchList = (props) => {
    const handleSearch = (event) => {
        props.filterData(event.target.value);
    };

    return (
        <>
            <div className="card card-widget card-primary collapsed-card">
                <div className="card-header">
                    <h3 className="card-title">Search</h3>

                    <div className="card-tools">
                        <button
                            type="button"
                            className="btn btn-tool"
                            data-card-widget="collapse"
                        >
                            <i className="fas fa-minus" />
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    <div>
                        <input
                            id="search"
                            type="text"
                            className="form-control"
                            placeholder="Input search keywords"
                            onChange={(event) => handleSearch(event)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        filterData: (filter) => dispatch(filterData(filter))
    };
};

export default connect(null, mapDispatchToProps)(BacklogSearchList);
