import axios from 'utils/axios';
import * as ActionTypes from './actions';

export function rssUrlsHasErrored(error) {
    return {
        type: ActionTypes.LOAD_RSSURLS_ERROR,
        error
    };
}
export function rssUrlsIsLoading(bool) {
    return {
        type: ActionTypes.LOAD_RSSURLS_LOADING,
        loading: bool
    };
}
export function rssUrlsFetchDataSuccess(data) {
    return {
        type: ActionTypes.LOAD_RSSURLS_SUCCESS,
        data
    };
}

export function rssUrlsFetchData() {
    return (dispatch) => {
        dispatch(rssUrlsIsLoading(true));
        axios
            .get('/rss-feed/get-latest')
            .then((response) => {
                dispatch(rssUrlsIsLoading(false));
                return response;
            })
            .then((response) => response.data)
            .then((data) => dispatch(rssUrlsFetchDataSuccess(data)))
            .catch((error) => dispatch(rssUrlsHasErrored(error)));
    };
}
