import React, {useState} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import {connect} from 'react-redux';
import {DateTime} from 'luxon';
import axios from 'utils/axios';
import {toast} from 'react-toastify';
import TweetCategoryBox from '@app/pages/backlog/TweetCategoryBox';
import TweetText from '../../components/tweet-text/TweetText';
import Button from '../../components/button/Button';
import Modal from '../../components/modal/Modal';
import {backlogFetchData} from '../../store/actions/tweets-backlog';

// const Tweet = ({data, rowRef, onImgLoad, user}) => {
const Tweet = ({data, rowRef, onImgLoad, fetchData}) => {
    const {url} = useRouteMatch();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleConfirm = () => {
        setShow(false);
        axios({
            method: 'post',
            url: '/backlog-tweet/delete',
            data: {tBacklogReference: data.tBacklogId}
        })
            .then(() => {
                // send notification
                toast.warn('Deleted');
                // console.log('AXIOS suuccceess');
                fetchData();
            })
            .catch((error) => {
                console.log('AXIOS failed', error);
                toast.warn(error);
            });
    };
    const handleShow = () => setShow(true);

    const warningMessage = `Are you sure you want to delete it?`;

    return (
        <div ref={rowRef} className="card card-widget">
            <div className="card-header">
                <div className="user-block">
                    <img
                        className="img-circle"
                        src="https://pbs.twimg.com/profile_images/709021104107433984/lrxKmheW_400x400.jpg"
                        // src={user.picture}
                        alt=""
                    />
                    <span className="username">Emmanuel Siegel</span>
                    <span className="description">
                        Created :{' '}
                        <span className="font-weight-bold">
                            {DateTime.fromISO(data.createdAt).toFormat(
                                'dd LLL yyyy'
                            )}
                        </span>
                        - Last Published:{' '}
                        <span className="font-weight-bold">
                            {data.lastPublished
                                ? DateTime.fromISO(data.lastPublished).toFormat(
                                      'dd LLL yyyy'
                                  )
                                : 'Not Yet'}
                        </span>
                    </span>
                </div>

                <div className="card-tools">
                    <TweetCategoryBox category={data.type} />
                    <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                    >
                        <i className="fas fa-minus" />
                    </button>
                </div>
            </div>

            <div className="card-body">
                <TweetText key={data.desc}>{data.content}</TweetText>

                {data.hashtags
                    ? data.hashtags.map((tag) => {
                          return (
                              <a key={tag} href={tag} className="mr-1">
                                  {tag}
                              </a>
                          );
                      })
                    : null}

                {data.imgCollection
                    ? data.imgCollection.map((image) => {
                          return (
                              <img
                                  onLoad={onImgLoad}
                                  key={image}
                                  className="img-fluid pad mb-3"
                                  src={`${process.env.REACT_APP_RELATIVE_URL_ROOT}/public/${image}`}
                                  alt=""
                              />
                          );
                      })
                    : null}

                {/* <a
                    href="https://twitter.com/emmanuel_siegel/"
                    target="twitterlink"
                >
                    <button type="button" className="btn btn-default btn-sm">
                        <i className="fab fa-twitter" /> Link to post
                    </button>
                </a> */}

                <br />
                <span className="float-right text-muted">
                    <Link to={`${url}/edit/${data.tBacklogId}`}>
                        <Button
                            type="button"
                            className="btn btn-primary btn-sm ml-2"
                        >
                            Edit
                        </Button>
                    </Link>

                    <Button
                        type="button"
                        className="btn btn-danger btn-sm ml-2"
                        onClick={handleShow}
                    >
                        Delete
                    </Button>

                    <Modal
                        handleClose={handleClose}
                        handleConfirm={handleConfirm}
                        show={show}
                        title="Confirmation"
                        body={warningMessage}
                    />
                </span>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: () => dispatch(backlogFetchData())
    };
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

export default connect(mapStateToProps, mapDispatchToProps)(Tweet);
