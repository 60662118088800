/* eslint-disable no-use-before-define */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import React, {useState, useEffect} from 'react';
import {useFormik} from 'formik';
import {useParams, useHistory} from 'react-router-dom';
import axios from 'utils/axios';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {Alert} from 'react-bootstrap';
import {connect} from 'react-redux';
import Button from '../../components/button/Button';
import {backlogFetchData} from '../../store/actions/tweets-backlog';
import {TweetType} from './TweetCategoryBox';
import InputTag from '../../components/input-tags/InputTags';
import './insert-tweet.css';

const FormData = require('form-data');

const InsertTweet = (props) => {
    const {editId} = useParams();
    const isAddMode = !editId;
    const history = useHistory();

    const [imgFiles, setImgFiles] = useState();
    const [letterCount, setLetterCount] = useState(0);
    const [tags, setTags] = useState([]);

    const handleHashtagsChange = (hashtags) => {
        setTags(hashtags);
    };

    const printFormError = (formik, key) => {
        if (formik.touched[key] && formik.errors[key]) {
            return (
                <Alert variant="warning" className="mt-2">
                    {formik.errors[key]}
                </Alert>
            );
        }
        return null;
    };

    const stringCount = (text) => {
        return text.length;
    };

    const handleTweetChange = (event) => {
        setLetterCount(stringCount(event.target.value));
    };

    const createRecord = (values, actions) => {
        // create new formdata to handle file upload
        const formData = new FormData();
        for (let i = 0; i < values.imageFiles.length; i += 1) {
            formData.append('imageFiles', values.imageFiles[i]);
        }
        formData.append('content', values.content);
        formData.append('type', values.type);
        formData.append('hashtags', JSON.stringify(tags));

        axios({
            method: 'post',
            url: '/backlog-tweet/add',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;'
            }
        })
            .then(() => {
                // success insert so reset form/data
                actions.setSubmitting(false);
                resetForm();
                toast.warn('Submitted');
                props.fetchData();
            })
            .catch((error) => {
                console.log('AXIOS failed', error);
                actions.setSubmitting(false);
                toast.warn(error.message);
            });
    };

    const updateRecord = (values, actions) => {
        // create new formdata to handle file upload
        const formData = new FormData();
        for (let i = 0; i < values.imageFiles.length; i += 1) {
            formData.append('imageFiles', values.imageFiles[i]);
        }
        formData.append('tBacklogReference', editId);
        formData.append('content', values.content);
        formData.append('type', values.type);
        formData.append('hashtags', JSON.stringify(tags));
        formData.append('lastPublished', values.lastPublished);

        axios({
            method: 'post',
            url: '/backlog-tweet/update',
            data: formData,
            headers: {'Content-Type': 'multipart/form-data'}
        })
            .then(() => {
                // success insert so reset form/data
                actions.setSubmitting(false);
                resetForm();
                toast.warn('Updated');
                props.fetchData();
                history.push('/backlog');
            })
            .catch((error) => {
                console.log('AXIOS failed', error);
                actions.setSubmitting(false);
                toast.warn(error.message);
            });
    };

    const formik = useFormik({
        initialValues: {
            content: '',
            type: '',
            hashtags: [],
            imageFiles: []
        },
        validationSchema: Yup.object({
            content: Yup.string(),
            type: Yup.string().required('Required')
        }),
        onSubmit: (values, actions) => {
            return isAddMode
                ? createRecord(values, actions)
                : updateRecord(values, actions);
        }
    });

    const resetForm = () => {
        formik.resetForm();
        setImgFiles([]);
        setLetterCount(0);
    };

    const uploadMultipleFiles = (e) => {
        const fileObj = [];
        const fileArray = [];
        fileObj.push(e.target.files);
        for (let i = 0; i < fileObj[0].length; i += 1) {
            fileArray.push(URL.createObjectURL(fileObj[0][i]));
        }
        setImgFiles(fileArray);
        formik.setFieldValue('imageFiles', e.target.files);
    };

    useEffect(() => {
        if (!isAddMode) {
            // get user and set form fields
            axios
                .get(`/backlog-tweet/get-details/${editId}`)

                .then((result) => {
                    console.log('AXIOS success', result.data);
                    formik.setFieldValue('content', result.data.content);
                    formik.setFieldValue('type', result.data.type);
                    formik.setFieldValue('hashtags', result.data.hashtags);
                    formik.setFieldValue(
                        'lastPublished',
                        result.data.lastPublished
                    );

                    formik.setFieldValue(
                        'imageFiles',
                        result.data.imgCollection
                    );
                    // setImgForm(result.data.imgCollection);
                    const fileArray = [];
                    for (
                        let i = 0;
                        i < result.data.imgCollection.length;
                        i += 1
                    ) {
                        fileArray.push(
                            `${process.env.REACT_APP_RELATIVE_URL_ROOT}/public/${result.data.imgCollection[i]}`
                        );
                    }
                    setImgFiles(fileArray);
                })
                .catch((error) => {
                    console.log('AXIOS failed', error);
                });
        } else {
            resetForm();
        }
    }, [editId]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div
                className={
                    isAddMode
                        ? 'card card-widget card-primary'
                        : 'card card-widget text-white bg-danger'
                }
            >
                <div className="card-header">
                    <h3 className="card-title">
                        {isAddMode ? 'Add New Tweet' : 'Edit Mode'}
                    </h3>

                    <div className="card-tools">
                        <button
                            type="button"
                            className="btn btn-tool"
                            data-card-widget="collapse"
                        >
                            <i className="fas fa-minus" />
                        </button>
                    </div>
                </div>

                <div className="card-body">
                    <div className="form-group">
                        <label htmlFor="inputSymbol">Tweet Content</label>
                        <textarea
                            type="text"
                            rows="5"
                            name="content"
                            className="form-control"
                            id="inputSymbol"
                            placeholder="Enter tweet"
                            onKeyUp={handleTweetChange}
                            // required
                            {...formik.getFieldProps('content')}
                        />
                        {printFormError(formik, 'content')}
                        <span>
                            Count: <b>{letterCount}</b>
                        </span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="selectType">Tweet Type</label>
                        <select
                            name="type"
                            className="form-control"
                            id="selectType"
                            {...formik.getFieldProps('type')}
                        >
                            <option value="" disabled>
                                Choose one
                            </option>
                            {(TweetType || []).map((type) => (
                                <option key={type}>{type}</option>
                            ))}
                        </select>
                        {printFormError(formik, 'type')}
                    </div>
                    <div className="form-group">
                        <label htmlFor="selectType">Hashtags</label>
                        <InputTag
                            onChange={handleHashtagsChange}
                            data={formik.values.hashtags}
                        />
                    </div>
                    <div className="form-group files">
                        <label htmlFor="imageUpload">
                            Tweet File Attachments
                        </label>
                        <div className="form-group multi-preview">
                            {(imgFiles || []).map((url) => (
                                <img
                                    key={url}
                                    src={url}
                                    alt="..."
                                    width="100"
                                    className="img-fluid pad mb-3 mr-3"
                                />
                            ))}
                        </div>
                        {isAddMode ? (
                            <input
                                type="file"
                                className="form-control"
                                multiple
                                name="image"
                                id="imageUpload"
                                onChange={uploadMultipleFiles}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                    {!isAddMode ? (
                        <div className="form-group">
                            <label htmlFor="inputDate">lastPublished</label>
                            <input
                                type="text"
                                name="lastPublished"
                                className="form-control"
                                id="inputDate"
                                {...formik.getFieldProps('lastPublished')}
                            />
                            {printFormError(formik, 'lastPublished')}
                        </div>
                    ) : (
                        ''
                    )}
                </div>

                <div className="card-footer">
                    <Button
                        block
                        type="buttomm"
                        isLoading={formik.isSubmitting}
                        disabled={formik.isSubmitting}
                        onClick={formik.handleSubmit}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </form>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: () => dispatch(backlogFetchData())
    };
};

export default connect(null, mapDispatchToProps)(InsertTweet);
